<template>
  <div class="container">
    <!-- <img src="img/logo-blue.png" alt=""> -->
    <h1>Відновлення паролю</h1>
    <p>Напишіть ваш e-mail для відновлення паролю</p>
    <form @submit.prevent="onSubmit">
      <input type="text" placeholder="Email"
        v-model.trim="email" 
        :class="{'invalid': $v.email.$dirty && !$v.email.required}"
      >
      <div class="login__btn">
        <button class="btn btn-primay btn btn-primary" type="submit">Отримати пароль</button>
        <router-link to="/login" class="login__forgot">Пароль знайшовся</router-link></div>
    </form>
  </div>
</template>

<style lang="scss">
  @import '../assets/login.css';
</style>

<script>
import {required} from 'vuelidate/lib/validators'

export default {
  name: 'PasswordRecovery',
  data: () => ({
    email: '',
  }),
  validations: {
    email: {required},
  },
  methods: {
    onSubmit(){
      if(this.$v.$invalid){
        this.$v.$touch()
        return
      }

      const formData = {
        email: this.email,
      }
      console.log(formData)
      this.$router.push('/login')
      
    }
  }
}


</script>
